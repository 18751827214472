import React from "react";
import "./Layout.css";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <header className="header">
        <div className="container">
          <h1>Rahul Shandilya's Travel Map</h1>
          <nav>
            <ul>
              <li>
                <a href="#about">About Me</a>
              </li>
              <li>
                <a href="#map">Travel Map</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main>{children}</main>

      <footer className="footer">
        <div className="container">
          <p>
            &copy; 2024 Rahul Shandilya |{" "}
            <a href="mailto:rahul@example.com">Contact Me</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
