import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Layout from "./components/Layout";
import visitedPlacesData from "./visited-places.json"; // Import your JSON

// Custom Marker Icon (Google-like marker)
import L from "leaflet";
const customIcon = new L.Icon({
  iconUrl: require("./assets/marker.png"), // Path to your custom marker image
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

// Component to fit map bounds based on markers
function FitBounds({ places }) {
  const map = useMap();

  React.useEffect(() => {
    if (places.length > 0) {
      const bounds = L.latLngBounds(
        places.map((place) => [place.lat, place.lng])
      );
      map.fitBounds(bounds);
    }
  }, [map, places]);

  return null;
}

function App() {
  const [visitedPlaces] = useState(visitedPlacesData);

  return (
    <Layout>
      <section id="about" className="about-section">
        <div className="container">
          <h2>About Me</h2>
          <p>
            Hi, I'm Rahul Shandilya, a software engineer and a travel
            enthusiast. This website showcases the places I've visited around
            the world. Below, you can find my interactive travel map that tracks
            my journeys.
          </p>
        </div>
      </section>

      <section id="map">
        <div className="container">
          <h2>Travel Map</h2>
          <MapContainer
            center={[20, 0]}
            zoom={2}
            style={{ height: "500px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {visitedPlaces.map((place, idx) => (
              <Marker
                key={idx}
                position={[place.lat, place.lng]}
                icon={customIcon}
              >
                <Popup>
                  <strong>{place.title}</strong>
                </Popup>
              </Marker>
            ))}
            <FitBounds places={visitedPlaces} />
          </MapContainer>
        </div>
      </section>
    </Layout>
  );
}

export default App;
